<template>
<v-main>

        <Header></Header>
        <!-- <v-container> -->
            
<div class="section5">
          <v-row>
                <v-col>
                        <div class="aurora2">
        <v-img  contain  max-height="30em" min-height="20em" :lazy-src="require('../assets/Resized Pictures/_DSC4870.webp')" :src="require('../assets/Resized Pictures/_DSC4870.webp')">
         </v-img> 
             </div>
                </v-col>
            </v-row>
        <!-- </v-container> -->
               
               

        <AboutExcel/>
        
        
        <v-container>
              <v-row>
            <v-col>


                <v-card-title class="justify-center">
                     <h4>
                       The Team  
                       </h4>
                </v-card-title>
            </v-col>
        </v-row>
        </v-container>
        <Team></Team>
        <Footer></Footer>
</div>
</v-main>
</template>


<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import AboutExcel from '../components/AboutExcel.vue'
import Team from '../components/Team.vue'

export default {
    name: 'About',
    components:{
    Header,
    Footer,
        AboutExcel,
        Team,
    },
    

}
</script>