<template>
<v-lazy transition="scroll-x-transition">

    <v-main>
         <v-container>
       <!-- <div class="space"> -->
      <v-row >
        <v-col>
         
           <v-card-title   primary>
              <h1>
                  The Code Effect
              </h1> 
            </v-card-title>
        </v-col>
       </v-row>
     </v-container>
             <v-container >
                <v-row>
                  <!-- <div class="space"> -->

                  <v-col>



              <p>    
             We are The Code Effect, a creative tech company that loves to design, collaborate, and create. We are a young team who dares to think big and isn’t afraid to be different. <br>             
             Our team is equipped with a variety of skills, unique experiences, and international exposure. <br> What we do: Web Design and Development, System Development, Mobile Apps,
             and Digital Marketing. We strive to offer our clients high quality software solutions that will maximize their business potential.
                </p>
                
                 <!-- </v-card-text> -->

                  </v-col>
                  <!-- </div> -->
                </v-row>
              </v-container>

                 <v-container>
                    <v-row>
                      <v-col lg="6" md="6" sm="12">
                      <div class="space" v-tilt="{glare:true, reverse:true}">
                        <v-container class="justify-center">
                          
                        <h2> Our Mission</h2><br>
                        <p>
                          We aspire to be a space for talents to collaborate and produce efficient and effective methods for the development of quality software.                         </p>
                        </v-container>
                      </div>
                    </v-col>
                    <v-col lg="6" md="6" sm="12"  v-tilt="{glare:true, reverse:true}" >
                        
                      <div class="space">
                      <v-container>
                        <h2>Our Vision</h2><br>
                        <p>
                          Transcending the Tech scene in Sudan with premium quality services and unique aesthetic.


                        </p>
                      </v-container>
                      </div>
    
                    </v-col>
                    </v-row>
                  </v-container>
            <!-- </div> -->
       <!-- </div> -->
       


    </v-main>
</v-lazy>
</template>

<script>
export default {
    name:'AboutExcel'
    
}
</script>