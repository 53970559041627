<template>
    <v-main>
        <!-- <v-container> -->
            
        <v-row class=" justify-center align-center" >
            <v-col  lg="4" md="6" sm="6"  v-for="item in items"  :key="item.id">
                <div  class="space" >
                <v-img contain class="white--text  align-end" :lazy-src="item.src" :src="item.src" >
                    <v-card-title  >
                            <!-- <h1> -->
                                <h3>



                            {{ item.title }}
                                </h3>
                            <!-- </h1> -->
                        </v-card-title>
                        <v-card-text>
                            {{ item.text }}
                        </v-card-text>
                </v-img>
                    </div> 

            </v-col>
        </v-row>
        <!-- </v-container> -->
    </v-main>
</template>
<script>
export default {
    name:'Team',
    data() {
        return {
            items:[
                {
                    id:2,
                    src: require('../assets/Resized Pictures/ahmed3.webp'),
                    title: 'Ahmed Behairi',
                    text:'Co-Founder'
                },
                {
                    id:1,
                    src: require('../assets/Resized Pictures/fish1.webp'),
                    title: 'Mahgoub Gaafar ',
                    text:'Co-Founder'
                }
            ]
        }
    },
}
</script>